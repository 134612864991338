import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Modal from '../../common/containers/Modal';
import Button from '../../common/components/Button';
import {
  clearDjangoValidationMessages,
  showDjangoValidationMessages,
} from '../../common/validation';
import { withNamespaces } from 'react-i18next';
import { AlertError } from '../../common/components/Alert';

class ShareButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      errorMessage: null,
      processing: false,
      sharingList: [],
      form: {
        can_edit: true,
        email: '',
        message: '',
        document: this.props.document.id,
      },
    };
    this.fetchSharingList = this.fetchSharingList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleShareClick = this.handleShareClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.remove = this.remove.bind(this);
    this.create = this.create.bind(this);
  }

  fetchSharingList() {
    this.setState((state) => ({
      ...state,
      errorMessage: null,
      processing: true,
      sharingList: [],
      form: {
        can_edit: true,
        email: '',
        message: '',
        document: this.props.document.id,
      },
    }));

    const url = `${Config.apiHost}sharing/?document=${this.props.document.id}`;
    Ajax.get(url).done((data) => {
      this.setState((state) => ({
        ...state,
        sharingList: data.results,
        processing: false,
      }));
    });
  }

  renderSharingListRows() {
    const { i18n } = this.props;
    return this.state.sharingList.map((share) => (
      <tr key={share.id}>
        <td>
          {share.email}
          &nbsp;
          <small className="text-muted">
            (
            {share.can_edit ? (
              <span>
                <i className="mdi mdi-edit" />{' '}
                {i18n.t('tem permissão para editar')}
              </span>
            ) : (
              <span>
                <i className="mdi mdi-eye" />{' '}
                {i18n.t('tem permissão para visualização apenas')}
              </span>
            )}
            )
          </small>
        </td>
        <td>
          <button
            className="btn btn-default btn-xs pull-right"
            onClick={() => this.remove(share.id)}
          >
            <i className="icon mdi mdi-delete" />
            &nbsp; Remover
          </button>
        </td>
      </tr>
    ));
  }

  remove(shareId) {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}sharing/${shareId}/?document=${this.props.document.id}`;
    Ajax.delete_(url)
      .done(() => {
        this.fetchSharingList();
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
      });
  }

  create(evt) {
    const { i18n } = this.props;
    this.setState((state) => ({
      ...state,
      processing: true,
    }));

    clearDjangoValidationMessages();
    const url = `${Config.apiHost}sharing/`;
    Ajax.post(url, { ...this.state.form })
      .done(() => {
        this.setState((state) => ({
          ...state,
          errorMessage: null,
          processing: false,
          form: {
            can_edit: true,
            email: '',
            message: '',
            document: this.props.document.id,
          },
        }));
        this.fetchSharingList();
      })
      .fail((jqXHR) => {
        showDjangoValidationMessages(jqXHR.responseJSON);
        this.setState((state) => ({
          ...state,
          processing: false,
          errorMessage: jqXHR.responseJSON
            ? jqXHR.responseJSON.detail
            : i18n.t('Erro inesperado'),
        }));
      });
    evt.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => ({
      ...state,
      form: {
        ...this.state.form,
        [name]: value,
      },
    }));
  }

  handleShareClick() {
    this.setState((state) => ({
      ...state,
      showModal: true,
    }));
    this.fetchSharingList();
  }

  handleModalClose() {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  }

  render() {
    const { i18n } = this.props;
    const title = i18n.t('Compartilhar o documento com outras pessoas');

    return (
      <>
        <a
          role="button"
          className={this.props.className}
          onClick={this.handleShareClick}
          title={this.props.showLabel ? '' : title}
        >
          <i className="icon mdi mdi-share" />
          &nbsp;
          {this.props.showLabel && (
            <span title={title}>{i18n.t('Compartilhar')}</span>
          )}
        </a>
        <Modal
          show={this.state.showModal}
          title={i18n.t('Compartilhar')}
          onCancel={this.handleModalClose}
          isProcessing={this.state.processing}
        >
          {this.state.errorMessage && (
            <AlertError>{this.state.errorMessage}</AlertError>
          )}

          <form onSubmit={this.create}>
            <div className="form-group">
              <input
                type="email"
                name="email"
                required={true}
                className="form-control"
                maxLength="254"
                placeholder="E-mail"
                onChange={this.handleInputChange}
                value={this.state.form.email}
              />
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="id_message"
                rows="2"
                placeholder={i18n.t('Mensagem (opcional)')}
                cols="40"
                className="form-control"
                onChange={this.handleInputChange}
                value={this.state.form.message}
              ></textarea>
              <div className="be-checkbox">
                <input
                  id="check_can_edit"
                  type="checkbox"
                  name="can_edit"
                  checked={this.state.form.can_edit}
                  onChange={this.handleInputChange}
                />
                <label htmlFor="check_can_edit">{i18n.t('Pode editar')}</label>
              </div>
            </div>
            <Button type="primary" size="lg" onClick={this.create}>
              {i18n.t('Compartilhar')}
            </Button>
          </form>
          <br />

          {this.state.sharingList && this.state.sharingList.length > 0 && (
            <>
              <p>{i18n.t('Esse documento foi compartilhado com')}</p>

              <table className="table table-condensed">
                <tbody>{this.renderSharingListRows()}</tbody>
              </table>
            </>
          )}
        </Modal>
      </>
    );
  }
}

ShareButton.propTypes = {
  document: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
};

ShareButton.defaultProps = {
  showLabel: true,
  className: 'btn btn-link',
};

export default withNamespaces()(ShareButton);
